* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.wave svg path {
  transform: translateY(100px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-container,
.ant-table-content,
.ant-table-body,
.ant-table-container * {
  transition: all 0.2s !important;
}

/* .ant-table-thead th {
  background-color: #F4F7FA!important;
} */

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-end-end-radius: 60px !important;
  border-start-end-radius: 60px !important;
}

.default-search-border-radius
> .ant-input-group
> .ant-input-group-addon:last-child
.ant-input-search-button{
  border-end-end-radius: 6px !important;
  border-start-end-radius: 6px !important;
}
.ant-select-focused,
.ant-select-focused * {
  border-color: none !important;
}

html {
  overflow-x: hidden;
}
p {
  margin-bottom: 0 !important;
}

.ant-checkbox-wrapper .ant-checkbox-wrapper-in-form-item span {
  width: 100% !important;
}

#unavailableItems > div > label {
  margin-left: 0px !important;
}

#unavailableItems > div > label > span:nth-child(2) {
  width: 100% !important;
}

textarea {
  border-radius: 25px !important;
}

.textarea-border {
  border-radius: 5px !important;
}

#statusId > div > div:nth-child(1) > label > span:nth-child(2) {
  display: flex;
  gap: 10px;
}

.ant-table-wrapper {
  width: 100%;
}

.hidden {
  display: none !important;
}

.ant-upload-list.ant-upload-list-text {
  display: none !important;
}

.selected-order-row {
  background-color: #e4343e50;
}



.span-counter {
  width: 30px;
  height: 30px;
  /* background: #f4f4f8; */
  border-radius: 2px;
  font-size: 14px;
  color: #ff6e4f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}

b{
  cursor: pointer;
}
.pointer{
  cursor: pointer;
}

.text-area-border-6{
  border-radius: 6px !important;
}