.new-chat-container .ant-tabs-tab {
  width: 100px;
}

.new-chat-container .chat-heads {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.new-chat-container .main-tab {
  transition: all 0.1s ease-in-out;
}

.new-chat-container .chat-heads:hover {
  background-color: #d7d7d7 !important;
}

.chatScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.chatScroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.chatScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}

.animate {
  animation: activeChatAnimation cubic-bezier(0, 0.54, 0.53, 1) 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
.animate * {
  color: #e4343e !important;
}
@keyframes activeChatAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
