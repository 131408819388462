.AssignAgentButton{
  border: 'none';
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  background: #1868C3;
  color: #fff;
  padding: 8px 12px;
}
.selectAgent{
  width: 100%;
}

.submitButton{
  color: #fff;
  background: #1868C3;

}
.textAreaStyle{
  border-radius: 8px !important;
}
.agentIconStyle{
  width: 30px;
}