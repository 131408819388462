/* 
.MasonryGrid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: masonry;
} */
 
.MasonryGrid{
display: grid;
gap: 20px;
grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}
.MasonryItem {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  grid-template-rows: masonry;
}

.MasonryItem img,
.MasonryItem video {
  width: 100%;
  height: auto;
  border-radius: 16px;

 


  border: 1px solid #000;
  background-color: #ffffff;

  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  transition: transform 0.3s;
 
  font-size: 1.2em;
  color: #000;
}

.MasonryItem:hover {
  transform: scale(1.02);
  /* Slight zoom effect on hover */
}

/* .LargeItem {
  grid-column: span 2;

  grid-row: span 2;

} */

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .MasonryGrid {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .MasonryGrid {
    grid-template-columns: 1fr;
    /* 1 column on small screens */
  }
}