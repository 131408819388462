:root {
  --background-light: #ffffff;
  --background-dark: #1a1a1a;
  --text-light: #313131;
  --text-dark: #f2f3f4;
  --border-light: #e8e8e8;
  --border-dark: #444;
  --primary-light: #c8d8f7;
  --primary-dark: #3a3a3a;
  --success-light: #1d9315;
  --success-dark: #4caf50;
  --error-light: #953f2c;
  --error-dark: #e4343e;
  --info-light: #4e4f60;
  --info-dark: #b0b0b0;
  --warn-light: #f9b04b;
  --warn-dark: #f57c20;
  --header-light: #fff5e7;
  --header-dark: #3a3a3a;

  --assigned-header-light: #F5CD47; 
  --assigned-header-dark: #3b3b3b; 

  --in-progress-header-light: #FD9891; 
  --in-progress-header-dark: #5a5a5a; 

  --done-header-light: #1F845A; 
  --done-header-dark: #4d4d4d; 
}


/* Light Mode */
body.light {
  --background: var(--background-light);
  --text-color: var(--text-light);
  --border-color: var(--border-light);
  --primary-color: var(--primary-light);
  --success-color: var(--success-light);
  --error-color: var(--error-light);
  --info-color: var(--info-light);
  --warn-color: var(--warn-light);
  --header-background: var(--header-light);
  
  --assigned-header: var(--assigned-header-light);
  --in-progress-header: var(--in-progress-header-light);
  --done-header: var(--done-header-light);



}


body.dark {
  --background: var(--background-dark);
  --text-color: var(--text-dark);
  --border-color: var(--border-dark);
  --primary-color: var(--primary-dark);
  --success-color: var(--success-dark);
  --error-color: var(--error-dark);
  --info-color: var(--info-dark);
  --warn-color: var(--warn-dark);
  --header-background: var(--header-dark);

  --assigned-header: var(--assigned-header-dark);
  --in-progress-header: var(--in-progress-header-dark);
  --done-header: var(--done-header-dark);




}






.taskCard {
  margin-top: 16px;
}

.taskCard {
  margin-bottom: 16px;
  padding: 16px;
background-color: var(--background);

  border-radius: var(--Spacing-md, 12px);
  background: var(--background);
  position: relative;
}

.cardHeader {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    gap: 4px;
  }
  align-items: center;
}

.cardTags {
}

.cardContent {
  margin-bottom: 8px;
}

.tag {
  margin: 12px 8px 12px 0;
}

.lateTime {
  display: flex;
  padding: var(--Spacing-2xs, 4px) var(--Spacing-sm, 8px);
  align-items: center;
  gap: var(--Spacing-2xs, 4px);
  border-radius: 14px;

  background: var(--background);
  color: var(--text-color);
  font-weight: 700;
  font-size: 12px;
}

.driverAndCustomer {
  color: var(--text-color);
  font-size: 12px;
  margin-bottom: 4px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.collapseContent {
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.headerColor {
  color: var(--text-color);
}
.ticketIdDev {
  border-radius: 20px;
  padding: 2px 8px;
background-color: var(--background);
  color: var(--text-color);
  text-transform: capitalize;
  cursor: pointer;
}
.headerBadge {
  position: absolute;
  top: -11px;
  left: 10px;
  gap: 4px;
  z-index: 4;
  padding: 2px 8px;
  flex-direction: row;
  display: flex;
}
.nameAndIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.sourceName {
  cursor: pointer;
  color: var(--text-color);
}
.dividerClass {
  border-bottom: 1px solid #e8e8e8;
  margin: 3px 0;
}
.menuButton {
  cursor: pointer;
}
.todo {
  cursor: pointer;
  background-color: #f9b04b;
  color: var(--text-color)'';
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  text-align: center;
  min-width: fit-content;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
  border-radius: 7px;
}
.in_progress {
  cursor: pointer;
  /* background-color: #000000; */
  background-color: var(--background);
  color: var(--text-color);
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  border-radius: 7px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
}
.done {
  cursor: pointer;
  background-color: #1d9315;
  color: var(--text-color);
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  text-align: center;
  border-radius: 7px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
}
.footerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.row {
  display: flex;
  align-items: center;

  gap: 8px;
}
.ticketTimer {
  color: var(--text-color);

  min-width: max-content;
}
.tooltip {
  z-index: 99;
}
.inputStyle {
  width: 100%;
  align-self: 'center';
  margin-right: auto;
  border: #00000040 solid 1px;
  border-radius: 8px;
  height: 25px;
  padding: 5px;
}

.customer {
  background-color: #e4343e;
}
.merchant {
  background-color: #0314a7;
}
.driver {
  background-color: #f77700;
}
.agent {
  background-color: #2a9d8f;
}
.buttonStatusIcon {
  font-size: 12px;
  margin-right: 400px;
}

.ticketLogStatusContainer {
  display: flex;
  gap: 8px;
  flex-direction: row;
  margin-bottom: 10px;
}
.columnCenter {
  display: flex;
  align-items: center;
  flex-direction: column;

}
.inTodoActive {
  background-color: #e4353f;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}
.inTodoInActive {
  border: 5px solid #e4353f;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}

.todoLine {
  background-color: #e4353f;
  height: 44px;
  width: 5px;
}

.inProgressActive {
  background-color: #F9B04C;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}
.inProgressInActive {
  border: 5px solid #F9B04C;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}

.inProgressLine {
  background-color: #F9B04C;
  height: 44px;
  width: 5px;
}
.doneActive {
  background-color: #0FAA02;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}
.doneInActive {
  border: 5px solid #0FAA02;

  border-radius: 30px;
  width: 30px;
  height: 30px;
}
.columnRight{
  display: flex;
  flex-direction: column;
  flex: 10;
  justify-content: center;


}
.todoDetails{
  display: flex;
  flex: 1;
  flex-direction: column;
  
}
.inProgressDetails{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  
}
.doneDetails{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: end;

  
}
.dividerClass{
  border-bottom: 1px solid #e8e8e8;
  margin: 3px 0;
}
.merchantIssueHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

}
.merchantIssueHeader_first{
  display: flex;
  color: #e4353f;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

}
.statusTextContainer{
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.dateSpan{
  color: #313131;
  font-size: 11px;
  max-width: 90px;
}


/* ////// */
.taskBoardContainer {
  padding: 20px;
  background-color: var(--background);
}

.statusRow {
  margin-bottom: 20px;
}

.statusButton {
  margin-right: 8px;
  border-radius: 8px;
}

.board-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.taskColumn {
  max-height: 100%;
  overflow-y: scroll;
  border-radius: 12px;
  flex: 1;
  background-color: var(--background);
}

.circle {
  font-weight: bold;
}

.stickyHeader {
  padding: 12px 24px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.backlogHeader {
  /* background-color: white; */
  background-color: var(--background);
}

.backlog-count,
.assign-count,
.inprogress-count,
.done-count {
  display: flex;
  padding: var(--Spacing-2xs, 4px) var(--Spacing-sm, 8px);
  align-items: center;
  gap: var(--Spacing-2xs, 4px);
  border-radius: 14px;
  font-style: normal;
  font-weight: 700;
  color: var(--text-color);
}

.backlog-count {
  background: #4e4f60;
  color: var(--text-color);
}

.assign-count {
  background: #f9b04b;
  color: var(--text-color);
}

.inprogress-count {
  background: var(--Lezzoo-Red, #e4343e);
}

.done-count {
  background: #1d9315;
}

.assignedHeader {
  background: #fff5e7;
}

.inProgressHeader {
  background: #fcebec;
}

.doneHeader {
  background: #e7f6e6;
}




.taskCard {
  margin-top: 16px;
}

.columnContainer {
  padding: 16px 24px;
}

.taskCard {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
  /* background-color: #fff; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  border-radius: var(--Spacing-md, 12px);
  border: 1px solid var(--Color-Gray-20, #e3e3e3);
  /* background: var(--Color-Base-White, #fff); */
  background: var(--background);
  position: relative;
  /* Shadow */
  box-shadow: 44px 56px 20px 0px rgba(0, 0, 0, 0),
    28px 36px 18px 0px rgba(0, 0, 0, 0), 16px 20px 15px 0px rgba(0, 0, 0, 0.01),
    7px 9px 11px 0px rgba(0, 0, 0, 0.01), 2px 2px 6px 0px rgba(0, 0, 0, 0.01),
    0px 0px 0px 0px rgba(0, 0, 0, 0.01);
}

.cardHeader {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    gap: 4px;
  }

  align-items: center;
}

.cardTags {
}

.cardContent {
  margin-bottom: 8px;
}

.tag {
  margin: 12px 8px 12px 0;
}

.lateTime {
  display: flex;
  padding: var(--Spacing-2xs, 4px) var(--Spacing-sm, 8px);
  align-items: center;
  gap: var(--Spacing-2xs, 4px);
  border-radius: 14px;
  /* background: #313131; */
  color: var(--text-color);
  font-weight: 700;
  font-size: 12px;
}


.merchantAccountManager{
  color: #919191;
font-family: "Euclid Circular B";
font-size: 12px;
font-style: normal;
font-weight: 400;
padding-top: 5px;
line-height: normal;
}
.subtitleContainer{
  display: flex;
  flex-direction: row;
  gap: 8px;
justify-content: space-between;
width: 100%;
}
.imageStyle{
  width: 20px;
  height: 20px;
  border-radius: 4px;

}
.merchantTextContainer{
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
 height: 100%;
 width: 100%;
}
.merchantName{
  color: var(--text-color);
font-family: "Euclid Circular B";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 14px; /* 140% */

}
.driverAndCustomer {
  color: var(--text-color);
  font-size: 12px;
  margin-bottom: 4px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.merchantContainer{
  border: 1px solid #f9bb6a;
  border-radius: 8px;
  color: var(--text-color);
  font-size: 12px;
  margin-bottom: 4px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  

}
.cardButtons {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  align-content: center;
}

.statusButton {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  /* background-color: transparent; */
  color: var(--text-color);
  gap: 4px;
}

.groupedContainer {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #16867b;
  border-color: #16867b;
padding:0px 6px;
  gap: 4px;
  margin-top: 10px;
}

.cta {
  border: none;
}

.arrowIcon {
  transition: transform 0.3s ease;

  &.collapsed {
    transform: rotate(-180deg);
  }
}

.collapseContent {
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.hidden {
  max-height: 0;
  opacity: 0;
  transition: transform 0.3s ease;
}

.headerColor {
  color: var(--text-color);
}

.ticketIdDev {
  border-radius: 20px;
  padding: 2px 8px;
  /* background-color: #313131; */
  background-color: var(--background);
  color: var(--text-color);
  text-transform: capitalize;
  cursor: pointer;
}

.headerBadge {
  position: absolute;
  top: -11px;
  left: 10px;
  gap: 4px;
  z-index: 4;
  padding: 2px 8px;
  flex-direction: row;
  display: flex;
}

.nameAndIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  /* width: 100%; */
}

.sourceName {
  cursor: pointer;
  color: var(--text-color);
}

.dividerClass {
  border-bottom: 1px solid #e8e8e8;
  margin: 3px 0;
}

.menuButton {
  cursor: pointer;
}

.todo {
  cursor: pointer;
  background-color: #f9b04b;
  color: var(--text-color);
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  text-align: center;
  min-width: fit-content;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
  border-radius: 7px;
}

.in_progress {
  cursor: pointer;
  /* background-color: #000000; */
  background-color: var(--background);
  color: var(--text-color);
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  border-radius: 7px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
}

.done {
  cursor: pointer;
  background-color: #1d9315;
  color: var(--text-color);
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  text-align: center;
  border-radius: 7px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
}

.footerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.row {
  display: flex;
  align-items: center;

  gap: 8px;
}

.ticketTimer {
  color: var(--text-color);

  min-width: max-content;
}

.tooltip {
  z-index: 99;
}

.inputStyle {
  width: 100%;
  align-self: 'center';
  margin-right: auto;
  border: #00000040 solid 1px;
  border-radius: 8px;
  height: 25px;
  padding: 5px;
}

.customer {
  background-color: #e4343e;
}

.merchant {
  background-color: #0314a7;
}

.driver {
  background-color: #f77700;
}

.agent {
  background-color: #2a9d8f;
}

.buttonStatusIcon {
  font-size: 12px;
  margin-right: 400px;
}

.expandableHeader {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  /* background-color: #f7f7f7; */
  background-color: var(--background);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  margin-top: 8px;
  padding: 5px;
  width: 100%;
  cursor: pointer;
}

.expandableCounter {
  background-color: red;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-weight: 700;
}

.expandableButtonContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

.groupContainerTask {
  display: flex;
  flex-direction: column;
  gap: 8px;

  overflow-y: scroll;
}

.subTaskModal {
  height: 70vh;
  margin-top: 20px;
  margin-bottom: 10px;
  overflow-y: scroll;
}

.timerAndCounterDev {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.showSomeMargin{
  margin-top: 10px;
}

.commentHeader{
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  
}

.commentContainer{
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin: 10px 0;
  padding: 10px;
}


.sendButton{
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.textArea{
  border-radius: 8px !important;
}
.commentInputContainer{
  position: relative;
}

.orderContainer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow: auto;
}
.orderCard{

  min-width: 490px;
}
.bigIcon{
  width: 16px;
}

/* classes.todayText:classes.dayText */
.dayText{
  color: var(--text-color);
  font-size: 16px;
  line-height: 16px;
}
.todayText{
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}
.shiftStatus{
  position: absolute;
  right: 2px;
  top: 0px;
}
.shiftContainer{
  position: relative;
}
.iconShift{
  color: #4ea44d;
}


.descriptionContainer {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;



}

.descriptionContainer h4 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.descriptionContainer p {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  margin: 0;
  word-wrap: break-word;
}

.descriptionContainer:hover {
  border-color: #cccccc;
  transform: translateY(-2px);
  transition: all 0.3s ease;
}
