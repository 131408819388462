.missing-items-container {
  width: 388px;
  border-radius: 10px;
  padding: 7.428px;
  /* background: #eee; */
  width: 100%;

  
}

.card-header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3,
  span {
    font-weight: bold;
  }

  h3 {
    font-size: 11.139px;
  }
  p,
  span {
    font-size: 8px;
  }
}


.cards{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.missing-items-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--Main-goten, #fff);
  padding: 7px;
  gap: 10px;
  

  .left-side {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  h4 {
    font-size: 9px;
    text-overflow: ellipsis;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
  }

  .image-container{
    position: relative;

  }
  .quantity{
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
  }

  .list {
    margin-top: 4px;
    list-style: none;
    
    li {
      color: #989898;
      font-size: 7.69px;
    }
  }
  
  p {
    margin-top: 4px;
    font-weight: bold;
    font-size: 9px;

  }
}
