.Row {
  display: flex;
  width: 100%;
  /* overflow: hidden; */
  flex-direction: row;
  gap: 2%;
}

.half-width {
  border: 2px solid grey;
  padding: 0px 2px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  text-overflow: ellipsis;
  width: 49%;
}

.customer-chat-icon {
  background-color: red;
  position: absolute;
  top: -12px;
  right: -2px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  color: #fff;
  /* width: 35px; */
  border-radius: 10px;
  width: 20px;
  height: 20px;
}
/* .motorbike,
  .person,
  .VipIcon {
    width: 1.8rem;
    height: 1.8rem;
  } */

.person {
  margin-right: 0.5rem;
}

.VipIcon {
  margin-right: 0.5rem;
}
.customer {
  display: flex;
  flex-direction: row;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
}

.driver {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-overflow: ellipsis;
  padding-right: 5px;
  overflow: hidden;

  flex: 1;
}

.customer-paragraph,
.driver-paragraph {
  position: relative;
  color: grey;
  font-weight: bold;

  display: flex;
  gap: 5px;
  text-overflow: ellipsis;
  word-wrap: none;
  overflow: hidden;
  font-size: 14px;

  white-space: nowrap;
  cursor: pointer;
}

.hasChat {
  border-color: #f1a0a4;
}

.customerIcons {
  display: flex;
  gap: 4px;
  align-items: center;
  padding-right: 3px;
}
