.invoice {
  width: 90%;
}

.invoice * {
  font-family: "Roboto", sans-serif;
}
.invoice table {
  border-top: 4px solid rgb(148, 10, 10);
  border-bottom: 4px solid rgb(148, 10, 10);
}
.invoice h1 {
  color: rgb(148, 10, 10);
}
.invoice .red {
  color: rgb(148, 10, 10);
}
/* select first tr */
.invoice th {
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  color: rgb(148, 10, 10);
}
.invoice td {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(213, 213, 213);
  padding-top: 20px;
}
/* select last .data */
.invoice tr:last-child td {
  border-bottom: none;
}

.invoice .total {
  width: 50%;
  padding-block: 10px;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
}
.final {
  border-bottom: 4px solid rgb(148, 10, 10);
}
.invoice .top {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.invoice .location {
  width: 50%;
}w
.invoice img {
  width: 70px;
}
.invoice .footer {
  margin-top: 30px;
}
.invoice .blue {
  color: rgb(48, 116, 183);
}


.textAreaStyle{
  width: 100% !important;
  border-radius: 8px !important;

}