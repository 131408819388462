
.customCard {
    cursor: pointer;
  
  }
  .customCard div:nth-child(1){
    padding: 5px  !important;
  }
  
.activeOrders{
    color: #FE6E4F;
    font-weight: bold;



}
.free{
    color: #209653;
    font-weight: bold;

}

.customCard:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .selectedDriver{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: #3F96FE 2px solid;
  }
  