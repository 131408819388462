:root {
  --background-light: #ffffff;
  --background-dark: #3B3030;
  --text-light: #313131;
  --text-dark: #f2f3f4;
  --border-light: #e8e8e8;
  --border-dark: #444;
  --primary-light: #c8d8f7;
  --primary-dark: #3a3a3a;
  --success-light: #1d9315;
  --success-dark: #4caf50;
  --error-light: #953f2c;
  --error-dark: #e4343e;
  --info-light: #4e4f60;
  --info-dark: #b0b0b0;
  --warn-light: #f9b04b;
  --warn-dark: #f57c20;
  --header-light: #fff5e7;
  --header-dark: #3a3a3a;

  --assigned-header-light: #F5CD47; 
  --assigned-header-dark: #3b3b3b; 

  --in-progress-header-light: #FD9891; 
  --in-progress-header-dark: #5a5a5a; 

  --done-header-light: #1F845A; 
  --done-header-dark: #4d4d4d; 
}


/* Light Mode */
body.light {
  --background: var(--background-light);
  --text-color: var(--text-light);
  --border-color: var(--border-light);
  --primary-color: var(--primary-light);
  --success-color: var(--success-light);
  --error-color: var(--error-light);
  --info-color: var(--info-light);
  --warn-color: var(--warn-light);
  --header-background: var(--header-light);
  
  --assigned-header: var(--assigned-header-light);
  --in-progress-header: var(--in-progress-header-light);
  --done-header: var(--done-header-light);



}


body.dark {
  --background: var(--background-dark);
  --text-color: var(--text-dark);
  --border-color: var(--border-dark);
  --primary-color: var(--primary-dark);
  --success-color: var(--success-dark);
  --error-color: var(--error-dark);
  --info-color: var(--info-dark);
  --warn-color: var(--warn-dark);
  --header-background: var(--header-dark);

  --assigned-header: var(--assigned-header-dark);
  --in-progress-header: var(--in-progress-header-dark);
  --done-header: var(--done-header-dark);




}




.taskBoardContainer {
  padding: 10px;
  width: 1508px;
  background: var(--background);
}

.idsContainer {
  display: flex;
  gap: 8px;
}
.id,
.tickets {
  display: inline-flex;
  height: 19.159px;
  padding: 8px !important;
  align-items: center;
  gap: var(--Spacing-2xs, 4px);
  flex-shrink: 0;
  border-radius: 14px;
  border: 1px solid #3753a6;
  background: #c8d8f7;
  color: var(--Color-Base-Black, #313131);
  font-weight: bold;
}
.tickets {
  border-radius: 14px;
  border: 1px solid #953f2c;
  background: #bd6767;
  color: var(--text-color);
}

.mainDev{
  background-color: var(--background);
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;

}
.statusRow {
  margin-bottom: 10px;
}

.statusButton {
  margin-right: 8px;
  border-radius: 8px;
}

.board-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.taskColumn {
  max-height: 100%;
  border-radius: 12px;
  flex: 1;
  background-color: var(--background);
  min-width: 500px
}

.circle {
  font-weight: bold;
}

.stickyHeader {
  padding: 12px 24px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.backlogHeader {
  background-color: var(--background);
}
.assign-count,
.inprogress-count,
.done-count {
  display: flex;
  padding: var(--Spacing-2xs, 4px) var(--Spacing-sm, 8px);
  align-items: center;
  gap: var(--Spacing-2xs, 4px);
  border-radius: 14px;
  font-style: normal;
  font-weight: 700;
  color: var(--text-color);
}
.ticketsContainer {
  display: flex;
  justify-content: space-between;
  /* padding: 0 10px 0 0; */
  margin-top: 4px;
}
.ticketIdContainer,
.merchantIdContainer {
  display: inline-flex;
  padding: var(--Spacing-2xs, 2px) var(--Spacing-sm, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  background: #9F8FEF;
  text-align: center;
  justify-content: center;
  color: var(--text-color);
  position: relative;
  overflow: auto;
  font-size: 10px;
}


.ticketIdContainerLeft{
  border-left:3px #e4343e solid;
  border-radius: 3px;
  padding-left: 3px;
  border-top-left-radius: 8px;
  position: absolute;
  left: -4px;



}
.ticketId {
  text-align: left;
}
.headerContainer{
  height: 33px;
  padding-top:3px;
  position: relative;
  align-self: center;
  overflow: visible;
}
.ticketIdTimer {
  text-align: center;
  width: 100%;
  justify-content: center;
}
.merchantIdContainer {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-direction: row;
  /* width: 60%; */
  position: absolute;
  right: 0px;
  /* padding-right: 120px; */
  gap: 8px;
  max-width: 220px;
}
.category {
  display: flex;
  height: 19.159px;
  padding: var(--Spacing-2xs, 4px) var(--Spacing-sm, 8px);
  align-items: center;
  gap: var(--Spacing-2xs, 4px);
  border-radius: 14px;
  border: 1px solid #787a8e;
  background: #787a8e;
  color: var(--text-color);
  
}
.backlog-count {
  background: var(--background);
  color: var(--text-color);
}

.assign-count {
  background: #f9b04b;
  color: var(--text-color);
}

.inprogress-count {
  background: var(--Lezzoo-Red, #e4343e);
}

.done-count {
  background: #1d9315;
}

.assignedHeader {
  background: var(--assigned-header-light);

}

.inProgressHeader {
  background: var(--in-progress-header-light); /* fallback color */
}

.doneHeader {
  background: var(--done-header-light); /* fallback color */
}


.taskCard {
  margin-top: 6px;
}
.cardBody {
  padding: 10px 10px 7px 10px;
}
.columnContainer {
  padding: 6px 24px;
}

.taskCard {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: var(--background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  border-radius: var(--Spacing-md, 12px);
  border: 1px solid var(--Color-Gray-20, #e3e3e3);
  position: relative;
  /* Shadow */
  min-height: 230px;
  box-shadow: 44px 56px 20px 0px rgba(0, 0, 0, 0),
    28px 36px 18px 0px rgba(0, 0, 0, 0), 16px 20px 15px 0px rgba(0, 0, 0, 0.01),
    7px 9px 11px 0px rgba(0, 0, 0, 0.01), 2px 2px 6px 0px rgba(0, 0, 0, 0.01),
    0px 0px 0px 0px rgba(0, 0, 0, 0.01);
}

.cardHeader {
  /* margin-bottom: 448px; */
  display: flex;

  justify-content: space-between;

  .left {
    display: flex;
    gap: 4px;
  }

  align-items: center;
}

.cardHeader .left {
  display: flex;
  gap: 4px;
}

.cardTags {
  /* margin-bottom: 8px; */
}

.cardContent {
  margin-bottom: 2px;
}

.tag {
  margin: 12px 8px 12px 0;
}



.merchantName {
  font-size: 12px;
  color: var(--text-color);
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;

}


.merchantContainer {
  border-radius: 8px;
  color: var(--text-color);
  font-size: 0px;
  margin-bottom: 2px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d6d7da;
}
.hasChat {
  border: 1px solid #f1a0a4;
}
.cardButtons {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  align-content: center;
}

.statusButton {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: var(--text-color);
  gap: 4px;
}

.groupedContainer {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #16867b;
  border-color: #16867b;
  padding: 0px 6px;
  gap: 4px;
  margin-top: 10px;
}

.cta {
  border: none;
}

.arrowIcon {
  transition: transform 0.3s ease;
}
.arrowIcon .collapsed {
  transform: rotate(-180deg);
}

.collapseContent {
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.hidden {
  max-height: 0;
  opacity: 0;
  transition: transform 0.3s ease;
}

.headerColor {
  color: var(--text-color);
}

.ticketIdDev {
  border-radius: 20px;
  padding: 2px 8px;
  background-color: var(--text-color);
  color: var(--text-color);
  text-transform: capitalize;
  cursor: pointer;
}

.headerBadge {
  position: absolute;
  top: -11px;
  left: 10px;
  gap: 4px;
  z-index: 4;
  padding: 2px 8px;
  flex-direction: row;
  display: flex;
}

.nameAndIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 4px;

  overflow: hidden;
}
.merchantNamecontainer {
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
}
.badgeContainer {
  position: relative;
}
.badge {
  width: 18px;
  height: 18px;

  position: absolute;
 
  background: var(--Lezzoo-Red, #e4343e);
  color: var(--text-color);
  border-radius: 20px;
  /* font-size: 7px; */
  top: -10px;
  right: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.sourceName {
  cursor: pointer;
  color: var(--text-color);
}

.dividerClass {
  border-bottom: 1px solid #e8e8e8;
  margin: 3px 0;
}

.menuButton {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.todo {
  cursor: pointer;
  border-color: #f9b04b;
  color: #f9b04b;
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  text-align: center;
  min-width: fit-content;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
  border-radius: 7px;
}

.in_progress {
  cursor: pointer;
  border-color: #000000;
  color: var(--text-color);
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  border-radius: 7px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
}

.done {
  cursor: pointer;
  border-color: #1d9315;
  color: #1d9315;
  padding: 0px 8px;
  height: 25px;
  line-height: 1;
  text-align: center;
  border-radius: 7px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  gap: 4px;
}

.footerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ticketTimer {
  color: var(--text-color);

  min-width: max-content;
}

.tooltip {
  z-index: 99;
}

.inputContainer {
  display: flex;
  align-items: center;
  border: 1px solid #d6d7da;
  margin-top: 2px;
  border-radius: 8px;
  overflow: hidden;
  height: 26px;
}
.inputContainer svg {
  margin-right: 5px;
}
.inputStyle {
  width: 97%;
  height: 25px;
  padding: 10px;
  border: none;
}
.inputStyle::placeholder {
  color: var(--text-color);
}

.customer {
  background-color: #e4343e;
}

.merchant {
  background-color: #0314a7;
}

.driver {
  background-color: #f77700;
}

.agent {
  background-color: #2a9d8f;
}

.buttonStatusIcon {
  font-size: 12px;
  margin-right: 4px;
}
.progress-time-circle {
  flex-shrink: 0;
  border-radius: 10px;
  background: #292929;
  color: white;
  padding: 1px 4px;
  font-size: 11px;
}

.expandableHeader {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  background-color: var(--background);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  margin-top: 8px;
  padding: 5px;
  width: 100%;
  cursor: pointer;
}

.expandableCounter {
  background-color: red;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-weight: 700;
}

.expandableButtonContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

.groupContainerTask {
  display: flex;
  flex-direction: column;
  gap: 8px;

  overflow-y: scroll;
}

.subTaskModal {
  max-height: 70vh;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 1550px;
  overflow-y: scroll;
}
.calSize{
  width: 500px ;
}
.modalBody{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

}

.timerAndCounterDev {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.lateTime{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.showSomeMargin {
  margin-top: 10px;
}

.statusCode {
  background-color: #767cb2 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  color: var(--text-color);
  font-size: 12px;
  padding: -0px 8px;
  height: 27px;
}
.task-container {
  display: flex;
  /* height: 18px; */
  padding: var(--Spacing-2xs, 4px) var(--Spacing-sm, 8px);
  align-items: center;
  justify-content: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #d6d7da;
  color: var(--text-color);
}

.progressContainer {
  border: 1px solid #292929;

  font-size: 12px;
  height: 27px;
  border-radius: 7px;
}

.agentTextContainer {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.agentText {
  margin: 7px 0;
  /* display: flex; */
  /* align-items: center; */
}
.agentText .lightColor {
  color: var(--text-color);
  font-size: 12px;
}
.tagsContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 8px;
  padding: 8px 0;
  cursor: default;

}

.tag {
  background-color: #f0f0f0;
  color: #333;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.merchantAccountManager{
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.parent{
  padding-left:3px ;
}
.selectStyle{
  width: 100%;
}